import { Text } from '@/components/text';
import { styled } from '@/stitches.config';
import { Statistic } from '@/types/sanity';
import { useRef } from 'react';
import CountUp from 'react-countup';
import { useIntersection } from 'react-use';
import { SanityKeyed } from 'sanity-codegen';

type Props = {
  alignment?: 'center' | 'left' | 'right';
  statistics: SanityKeyed<Omit<Statistic, '_type'>>[];
};

export function Statistics({ alignment = 'left', statistics }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const showStatistics = intersection && intersection.intersectionRatio > 0;

  const calculateStatStartValue = (value: number) => {
    if (value < 10) {
      return 0;
    } else {
      return value * 0.9;
    }
  };

  return (
    <Section>
      <Text
        size={2}
        as="h2"
        css={{
          fontWeight: '$heavy',
          '@bp3': {
            marginBottom: '$6',
          },
        }}
      >
        Statistics
      </Text>

      <Flex alignment={alignment} ref={ref}>
        {showStatistics &&
          statistics.map((statistic) => (
            <IndividualStat key={statistic._key}>
              <CountUpStat
                start={calculateStatStartValue(statistic.value)}
                statistic={statistic}
                decimals={Number.isInteger(statistic.value) ? 0 : 2}
              />
              <Label size={2}>{statistic.title}</Label>
            </IndividualStat>
          ))}
      </Flex>
    </Section>
  );
}

const Section = styled('section', {
  gridColumn: 'wide-start',
  gridColumnGap: '$5',
});

const Flex = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$7',
  '@bp2': {
    gap: '$8',
  },
  '@bp3': {
    flexDirection: 'row',
    gap: '$10',
  },
  '@bp7': {
    gap: '$11',
  },
  variants: {
    alignment: {
      left: {
        '@bp3': {
          justifyContent: 'flex-start',
        },
      },
      center: {
        '@bp3': {
          justifyContent: 'center',
        },
      },
      right: {
        '@bp3': {
          justifyContent: 'flex-end',
        },
      },
    },
  },
});

const IndividualStat = styled('div', {
  display: 'inline-flex',
  flexDirection: 'column',
  fontVariantNumeric: 'tabular-nums',
});

const Label = styled(Text, {
  maxWidth: '10rem',
  minWidth: '$10',
});

type CountUpProps = {
  decimals: number;
  start: number;
  statistic: SanityKeyed<Omit<Statistic, '_type'>>;
};

function CountUpStat({ decimals, start, statistic }: CountUpProps) {
  return (
    <CountUp
      start={start}
      end={statistic.value}
      delay={0}
      decimal=","
      duration={2}
      decimals={decimals}
      prefix={statistic.prefix ? statistic.prefix : ''}
      suffix={statistic.symbol ? statistic.symbol : ''}
    >
      {({ countUpRef }) => (
        <Text
          size={{
            '@initial': 6,
            '@bp2': 8,
            '@bp3': 13,
            '@bp4': 15,
          }}
          css={{
            marginBottom: '0',
            lineHeight: '$1',
            // uses tabular numbers to avoid layout shifts
            fontFeatureSettings: '"tnum" 1',
            '@bp4': {
              ml: '-$2',
            },
          }}
          ref={countUpRef}
        />
      )}
    </CountUp>
  );
}
