import { ExpandingImage } from '@/components/expanding-image';
import { aspectRatioStyles } from '@/components/serializers/gallery/shared';
import { CapsizedText as Text } from '@/components/text';
import { styled } from '@/stitches.config';
import { SanityResolvedImage } from '@/types';
import { textToP } from '@/utils/text-to-p';

type ExpandableImageProps = {
  image?: SanityResolvedImage<{ alt?: string }>;
  text?: string;
};

export function ExpandableImage({ image, text }: ExpandableImageProps) {
  return (
    <>
      <FirstColumn>
        {text && (
          <>
            {textToP(text).map((t, i) => (
              <Text key={i}>{t}</Text>
            ))}
          </>
        )}
      </FirstColumn>
      <SecondColumn>{image && <ExpandingImage image={image} />}</SecondColumn>
    </>
  );
}

const FirstColumn = styled('div', {
  unset: 'grid-column',
  '@bp3': {
    gridColumn: '3 / span 7',
  },
  '@bp5': {
    gridColumn: '3 / span 5',
  },
});

const SecondColumn = styled('div', {
  unset: 'grid-column',
  position: 'relative',
  '> div': aspectRatioStyles,
  '@bp3': {
    gridColumn: '11 / span 7',
  },
  '@bp5': {
    gridColumn: '12 / span 6',
  },
});
