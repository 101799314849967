import { Box } from '@/components/box';
import { Prose } from '@/components/prose';
import { useDebugStore } from '@/hooks/use-debug-store';
import {
  blockWithoutContainers,
  TextAndResolvedImages,
  typesWithoutContainers,
} from '@/lib/sanity/portable-text';
import { urlForImage } from '@/lib/sanity/sanity';
import { styled } from '@/stitches.config';
import { SanityResolvedImage } from '@/types';
import { PortableText } from '@portabletext/react';
import NextImage from 'next/image';
import { useCallback, useMemo } from 'react';

import { hasPresentKey } from 'ts-is-present';
import { Vimeo } from './vimeo';

import { Vimeo as SanityVimeo } from '@/types/sanity';
import { TreemapImage } from '../treemap-image';

type ImageProps = Omit<
  SanityResolvedImage<{
    alt?: string;
    overrideAspectRatio?: boolean;
    width?: number;
    height?: number;
  }>,
  '_type'
>;

interface VideoItemProps extends SanityVimeo {
  overrideAspectRatio?: boolean;
  width?: number;
  height?: number;
}

type SharedProps = {
  isRightAligned?: boolean;
  isSticky?: boolean;
};

type TextProps = SharedProps & Pick<TextAndResolvedImages, 'text'>;
type ImagesProps = SharedProps & Pick<TextAndResolvedImages, 'imageRows'>;
type TextAndImagesProps = Omit<TextAndResolvedImages, '_type'>;

const leftColumnText = '1 / span 5';
const rightColumnText = '10 / span 5';

const leftColumnImage = '1 / span 8';
const rightColumnImage = '7 / span 8';

function Image(props: ImageProps) {
  const {
    asset,
    alt,
    disableTreemapAnimation,
    overrideAspectRatio,
    width,
    height,
  } = props;

  const { metadata } = asset;
  if (!metadata) {
    return null;
  }
  const { lqip } = metadata;
  const imageUrl = urlForImage(asset).url();

  if (!imageUrl) {
    return null;
  }

  // Override the aspect ratio if the toggle is active and both width and height are defined
  let aspectRatioWidth = 16;
  let aspectRatioHeight = 9;

  if (overrideAspectRatio && width && height) {
    aspectRatioWidth = width;
    aspectRatioHeight = height;
  }

  return (
    <Box
      css={{
        position: 'relative',
        aspectRatio: `${aspectRatioWidth} / ${aspectRatioHeight}`,
        '@supports not (aspect-ratio: 16 / 9)': {
          paddingBottom: `${100 / (aspectRatioWidth / aspectRatioHeight)}%`,
        },
      }}
    >
      {disableTreemapAnimation ? (
        <NextImage
          alt={alt}
          draggable="false"
          src={imageUrl}
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL={lqip}
        />
      ) : (
        <TreemapImage
          alt={alt}
          draggable="false"
          src={imageUrl}
          layout="fill"
          objectFit="cover"
          palette={metadata.palette}
        />
      )}
    </Box>
  );
}

function Video(
  props: Omit<VideoItemProps, '_type' | 'posterFrame'> & {
    videoPosterFrame?: VideoItemProps['posterFrame'];
  }
) {
  const posterFrame = props.videoPosterFrame
    ? urlForImage(props.videoPosterFrame?.asset).width(1024).url() ?? undefined
    : undefined;

  return (
    <Vimeo
      posterFrame={posterFrame}
      shouldLoop={props.shouldLoop ? true : false}
      shouldHideControls={props.shouldHideControls ? true : false}
      {...props}
    />
  );
}

export function Text(props: TextProps) {
  const { text, isSticky, isRightAligned } = props;

  return (
    <Box
      css={{
        gridColumn: isRightAligned ? leftColumnText : rightColumnText,
      }}
    >
      <Content isSticky={isSticky}>
        <Prose>
          {text && (
            <PortableText
              value={text}
              components={{
                block: blockWithoutContainers,
                types: typesWithoutContainers,
              }}
            />
          )}
        </Prose>
      </Content>
    </Box>
  );
}

export function Images(props: ImagesProps) {
  const { imageRows, isSticky, isRightAligned } = props;

  const rows = useMemo(() => {
    if (!imageRows) return [];
    return imageRows?.filter(hasPresentKey('images'));
  }, [imageRows]);

  return (
    <Box
      css={{
        mb: '$6',
        '@bp2': {
          mb: '$8',
        },
        '@bp3': {
          gridColumn: isRightAligned ? rightColumnImage : leftColumnImage,
          mb: 0,
        },
      }}
    >
      <Content isSticky={isSticky}>
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '$6',
            '@bp3': {
              gap: '$6',
            },
            '@bp4': {
              gap: '$8',
            },
          }}
        >
          {rows.map((row, rowIndex) => {
            const { overrideAspectRatio, width, height, images } = row;

            const key = images
              .map((img, imgIndex) =>
                'asset' in img ? img.asset._id : [rowIndex, imgIndex].join('-')
              )
              .join('-');
            return (
              <ImageRow key={key} double={images.length === 2}>
                {images.map((item) => {
                  return (
                    // Wrapping it in a div to make sure aspect ratio is maintained
                    <div key={item._key}>
                      {item._type === 'image' ? (
                        <Image
                          alt={item.alt}
                          asset={item.asset}
                          disableTreemapAnimation={item.disableTreemapAnimation}
                          overrideAspectRatio={overrideAspectRatio}
                          width={width}
                          height={height}
                        />
                      ) : (
                        <Box
                          css={{
                            /**
                             *  Visually removes black outline from the embedded Vimeo content which is due to some weird rounding of numbers even when the correct aspect ratio is enforeced.
                             *  Currently, this is the only solution that works for different browsers at different zoom levels at well.
                             *  Solution taken from: https://github.com/twbs/bootstrap/issues/26284#issuecomment-392017929
                             */
                            clipPath: 'inset(1px)',
                          }}
                        >
                          <Video
                            display="normal"
                            videoId={item.videoId}
                            videoPosterFrame={item.posterFrame}
                            overrideAspectRatio={overrideAspectRatio}
                            width={width}
                            height={height}
                            shouldAutoplay={item.shouldAutoplay}
                            shouldLoop={item.shouldLoop}
                            shouldHideControls={item.shouldHideControls}
                            shouldUseClippingMask={item.shouldUseClippingMask}
                          />
                        </Box>
                      )}
                    </div>
                  );
                })}
              </ImageRow>
            );
          })}
        </Box>
      </Content>
    </Box>
  );
}

export function TextAndImages(props: TextAndImagesProps) {
  const {
    text,
    isRightAligned = false,
    imageRows,
    stickyText,
    stickyMedia,
  } = props;
  const isDebugActive = useDebugStore(useCallback((state) => state.debug, []));

  const sharedProps = {
    isDebugActive,
    isRightAligned: isRightAligned ? true : false,
  };

  const textProps = { ...sharedProps, text, isSticky: stickyText };
  const imagesProps = { ...sharedProps, imageRows, isSticky: stickyMedia };

  return (
    <TextAndImagesContainer>
      <Images {...imagesProps} isRightAligned={isRightAligned ? true : false} />
      <Text {...textProps} isRightAligned={isRightAligned ? true : false} />
    </TextAndImagesContainer>
  );
}

export const TextAndImagesContainer = styled('div', {
  '@bp3': {
    display: 'grid',
    margin: 0,
    padding: 0,
    gridAutoFlow: 'column dense',
    gridTemplateColumns: 'repeat(14, minmax(0, 1fr))',
    gridColumnGap: '$6',
  },
});

const Content = styled('div', {
  variants: {
    isSticky: {
      true: {
        position: 'sticky',
        top: '$9',
      },
    },
  },
});

const ImageRow = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  '@bp3': {
    '&:nth-child(1)': {
      gridColumn: 'main-start / span 7',
    },
    '&:nth-child(2)': {
      gridColumn: '11 / span 6',
    },
  },
  variants: {
    double: {
      true: {
        display: 'grid',
        gap: '$6',
        '@bp3': {
          gridTemplateColumns: '1fr 1fr',
          gap: '$6',
        },
        '@bp4': {
          gap: '$8',
        },
      },
    },
  },
});
