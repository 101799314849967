import React from 'react';
import { styled, CSS } from '@/stitches.config';
import { Text } from '@/components/text';
import {
  focusStateStyles,
  Underline,
  underlineHoverStyles,
} from '@/components/underline';
import {
  ExternalLinkXsmall as IconExternal,
  ArrowRightXsmall as IconArrowRight,
} from '@/components/icons';
import NextLink from 'next/link';

const BUTTON_SIZE = 20;

type ButtonProps = {
  href: string;
  label: string;
  css?: CSS;
  onClick?: (e: React.MouseEvent) => void;
  isInternalLink?: boolean;
};

export function ButtonText({
  href,
  label,
  onClick,
  css,
  isInternalLink = true,
}: ButtonProps) {
  return (
    <NextLink href={href} passHref>
      <ButtonLink
        css={css}
        target={isInternalLink ? '_self' : '_blank'}
        onClick={onClick}
      >
        <ButtonIconWrapper>
          <ButtonIcon isInternalLink={isInternalLink} />
          <IconUnderLine />
        </ButtonIconWrapper>

        <ButtonLinkLabel>
          <Text
            size={2}
            css={{
              display: 'block',
              padding: '$2',
              margin: 0,
              lineHeight: '$5',
            }}
          >
            {label}
          </Text>
          <Underline />
        </ButtonLinkLabel>
      </ButtonLink>
    </NextLink>
  );
}

type ButtonIconProps = {
  isInternalLink: boolean;
};

export function ButtonIcon({ isInternalLink }: ButtonIconProps) {
  if (isInternalLink) {
    return (
      <IconArrowRight
        width={BUTTON_SIZE}
        height={BUTTON_SIZE}
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          transform: 'translateY(-50%)',
          width: '100%',
          height: '100%',
          stroke: 'currentcolor',
          strokeWidth: 0,
        }}
      />
    );
  } else {
    return (
      <IconExternal
        width={BUTTON_SIZE}
        height={BUTTON_SIZE}
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          transform: 'translateY(-50%)',
          width: '100%',
          height: '100%',
          stroke: 'currentcolor',
          strokeWidth: 0,
        }}
      />
    );
  }
}

const IconUnderLine = styled('div', {
  position: 'absolute',
  bottom: '-4px',
  width: '100%',
  height: '4px',
  backgroundColor: 'currentColor',
  transition: 'transform',
});

const iconUnderlineHover = {
  ...underlineHoverStyles,
  animationDuration: '200ms',
};

const textUnderlineHover = {
  ...underlineHoverStyles,
  animationDuration: '350ms',
};

const ButtonLink = styled('a', {
  overflow: 'hidden',
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  color: '$foreground',

  '&:hover, &:active': {
    [`${Underline} > div `]: textUnderlineHover,
    [`${IconUnderLine}`]: iconUnderlineHover,
  },

  '&:focus-visible': {
    ...focusStateStyles,

    [`${Underline} > div `]: textUnderlineHover,
    [`${IconUnderLine}`]: iconUnderlineHover,
  },

  '@noHover': {
    scrollSnapAlign: 'start',
  },
});

const ButtonIconWrapper = styled('div', {
  position: 'relative',
  width: BUTTON_SIZE + 'px',
});

const ButtonLinkLabel = styled('div', {
  position: 'relative',
});
