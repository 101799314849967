import { Box } from '@/components/box';
import {
  ICON_BLOCK_DEFAULT_CLIP_PATH,
  IconOverlay,
} from '@/components/icon-overlay';
import * as AccessibleIcon from '@/components/icons';
import { ExternalLink as ExternalLinkIcon } from '@/components/icons';
import { aspectRatioStyles } from '@/components/serializers/gallery/shared';
import { Text } from '@/components/text';
import type { PublicityItem as PublicityItemType } from '@/lib/sanity/portable-text';
import { urlForImage } from '@/lib/sanity/sanity';
import { styled } from '@/stitches.config';
import { motion } from 'framer-motion';
import Image from 'next/image';

import { SliderComposeable } from '../../core/slider-composeable';

const config = {
  slides: {
    perView: 1.4,
  },
  breakpoints: {
    '(min-width: 540px)': {
      slides: {
        perView: 2.5,
      },
    },
    '(min-width: 768px)': {
      slides: {
        perView: 3.5,
      },
    },
    '(min-width: 1024px)': {
      slides: {
        perView: 4.5,
      },
    },
  },
};

type PublicityItemsProps = {
  title?: string;
  publicityItems: PublicityItemType[];
};

export function Publicity({ title, publicityItems }: PublicityItemsProps) {
  return (
    <>
      <Title>{title ?? 'Publicity'}</Title>
      <Box
        css={{
          gridColumn: 'full / full',
          overflow: 'hidden',
          position: 'relative',
          paddingLeft: '$4',
          '@bp3': {
            paddingLeft: '$6',
          },
          '@bp4': {
            paddingLeft: '$8',
          },
        }}
      >
        <SliderComposeable
          config={config}
          css={{
            paddingRight: '$6',
            overflow: 'visible !important', // Important because keen slider wants it by default hidden
          }}
          slides={publicityItems.map((item, index) => {
            return (
              <SlideContainer
                key={item._key}
                className={`keen-slider__slide number-slide${index + 1}`}
              >
                <PublicityItem publicityItem={item} />
              </SlideContainer>
            );
          })}
        />
      </Box>
    </>
  );
}

const Overlay = styled('div', {
  position: 'absolute',
  margin: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: 'rgba(0, 0, 15, 0.25)',
  transition: 'opacity 0.2s linear',
  opacity: 1,
  pointerEvents: 'none',
  zIndex: '$overlay',
});

const SlideContainer = styled('div', {
  paddingRight: '$6',
  overflow: 'visible !important', // Important because keen slider wants it by default hidden
});

const Title = styled('div', {
  fontSize: '$2',
  fontWeight: '$heavy',
  marginBottom: '$3',
  gridColumn: 'full / full',
  paddingLeft: '$4',
  '@bp3': {
    paddingLeft: '$6',
  },
  '@bp4': {
    paddingLeft: '$8',
  },
});

type PublicityItemProps = {
  publicityItem: PublicityItemType;
};

function PublicityItem({ publicityItem }: PublicityItemProps) {
  const { source, image, blurb, url } = publicityItem;

  if (!image) return null;

  // const { metadata } = image.asset;
  // const { palette } = metadata;
  const imageUrl = urlForImage(image.asset).url();

  if (!imageUrl) return null;

  return (
    <Item
      href={url}
      target="_blank"
      onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
      onTap={() => {
        window.open(url, '_blank')?.focus();
      }}
      css={{
        border: 'none !important',
        color: '$foreground',
        textDecoration: 'none',
      }}
    >
      <ImageContainer>
        <Overlay />
        <Image
          alt={`Image for ${source} publicity item`}
          src={imageUrl}
          layout="fill"
          objectFit="cover"
          objectPosition={'top center'}
          onDragStart={(e) => e.preventDefault()}
        />
        <IconOverlay>
          <AccessibleIcon.Root label="Icon indicating link is from an external source and will open in new tab">
            <ExternalLinkIcon
              width={20}
              height={20}
              style={{
                stroke: 'currentcolor',
                strokeWidth: 0.5,
                position: 'relative',
              }}
            />
          </AccessibleIcon.Root>
          <HoverBlock aria-hidden="true">
            <DuplicateIconWrapper>
              <ExternalLinkIcon
                width={20}
                height={20}
                style={{ stroke: 'currentcolor', strokeWidth: 0.5 }}
              />
            </DuplicateIconWrapper>
          </HoverBlock>
        </IconOverlay>
      </ImageContainer>
      <Text size={2} as="p" css={{ mt: '$4', mb: '$2' }}>
        {blurb}
      </Text>
      <Text
        as="p"
        size={1}
        css={{
          my: 0,
          '&:before': {
            content: '—',
            mr: '$2',
          },
        }}
      >
        {source}
      </Text>
    </Item>
  );
}

const Item = styled(motion.a, {
  position: 'relative',
});

const ImageContainer = styled('div', aspectRatioStyles, {
  '&:hover': {
    [`& ${Overlay}`]: {
      opacity: 0,
    },
  },
});

const HoverBlock = styled('div', {
  display: 'flex',
  position: 'absolute',
  bottom: 0,
  left: 0,
  padding: '$2',

  backgroundColor: '$foreground',

  ...ICON_BLOCK_DEFAULT_CLIP_PATH,

  transition: 'clip-path 320ms ease-out',
});

const DuplicateIconWrapper = styled('div', {
  position: 'relative',
  svg: {
    color: '$background',
    stroke: '$background',
  },
});
