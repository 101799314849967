import React from 'react';
import { styled } from '@/stitches.config';
import { getInternalLinkHref } from '@/utils/getInternalLinkHref';
import { getExternalLinkHref } from '@/utils/getExternalLinkHref';
import NextLink from 'next/link';

const A = styled('a');

//@TODO Fix this component

import type { InternalOrExternalLinkProps } from '@/lib/sanity/portable-text';

export function InternalOrExternalLink(props: InternalOrExternalLinkProps) {
  const { value, text } = props;

  //@ts-expect-error The following values exist, but TS does not understand the sanity generic?
  const { suffix, internalLink, type, link } = value;

  let href;
  if (type === 'internal') {
    href = getInternalLinkHref(internalLink, suffix);
  }

  if (type === 'external') {
    href = getExternalLinkHref(link, suffix);
  }

  if (!href) {
    console.warn('Can not render this! You need a valid href');
    return null;
  }

  if (type === 'internal') {
    return (
      <NextLink
        href={href}
        passHref
        // Do not scroll to the top of the page if we provide a suffix (a section on the page)
        // scroll={false}
      >
        <A
          css={{
            fontWeight: 500,
            display: 'inline',
            color: '$foreground',
          }}
        >
          {text}
        </A>
      </NextLink>
    );
  }

  if (type === 'external') {
    return (
      <A
        css={{
          fontWeight: 500,
          display: 'inline',
        }}
        href={href}
      >
        {text}
      </A>
    );
  }

  return null;
}
