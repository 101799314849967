import React from 'react';
import { ArrowRightSmall, ArrowLeftSmall } from '@/components/icons';
import { CarouselArrowButton } from '@/components/serializers/sliders/common/carousel-arrow-button';
import { KeenSliderInstance } from 'keen-slider';

import { styled } from '@/stitches.config';

const Arrow = styled(CarouselArrowButton, {
  position: 'absolute',
  color: '$foreground',
  top: '50%',
  translate: '0 -100%',
});

export const ArrowLeftButton = styled(Arrow, {
  translateY: '50%',
  left: '$4',
  '@bp3': {
    left: '$8',
  },
});

export const ArrowRightButton = styled(Arrow, {
  translateY: '50%',
  right: '$4',
  '@bp3': {
    right: '$8',
  },
});

type ArrowsProps = {
  loaded: boolean;
  instanceRef: React.MutableRefObject<KeenSliderInstance | null>;
  currentSlide: number;
};
export function Arrows({ loaded, instanceRef, currentSlide }: ArrowsProps) {
  if (!loaded || !instanceRef.current) {
    return null;
  }
  if (typeof currentSlide !== 'number') {
    return null;
  }

  return (
    <>
      <ArrowLeftButton
        aria-label="Go left in carousel"
        tabIndex={-1}
        as={CarouselArrowButton}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          instanceRef.current?.prev();
        }}
        disabled={currentSlide === 0}
      >
        <ArrowLeftSmall />
      </ArrowLeftButton>
      <ArrowRightButton
        aria-label="Go right in carousel"
        tabIndex={-1}
        as={CarouselArrowButton}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          instanceRef.current?.next();
        }}
        disabled={currentSlide === instanceRef.current.track.details.maxIdx}
      >
        <ArrowRightSmall />
      </ArrowRightButton>
    </>
  );
}
